.Modal {
  position: absolute;
  margin: auto;
  background-color: #101010;
  border: 1px solid rgb(65, 30, 23);
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  left: 40px;
  right: 40px;
  top: 10rem;
  max-width: fit-content;
  padding: 1rem 9rem;
}
.nami {
  max-width: 2.5rem;
}
.ccvault {
  max-width: 3rem;
  max-height: 3rem;
}
.nufi {
  max-width: 3.5rem;
  max-height: 3.5rem;
  border-radius: 0.5rem;
}
.gero {
  max-width: 3rem;
  max-height: 3rem;
  border-radius: 0.5rem;
}
.wallet-container {
  display: flex;
  justify-content: center;
  justify-items: center;
}
.wallet-container p {
  margin-left: 0.2rem;
  margin-right: 1rem;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
