@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;1,100&family=Roboto+Mono:ital,wght@0,100;0,200;0,400;1,100;1,200&family=Urbanist:ital,wght@0,100;0,200;0,400;1,100&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container {
  padding-top: 0.8rem;
  padding-left: 1.2rem;
  width: 10rem;
  justify-content: center;
  justify-items: center;
  margin: 1.5rem;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  color: white;
  cursor: pointer;
  border-top: 1px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #3f1b40, #743ad5);
  transition: all 200ms linear;
}
.container:hover {
  transition: 200ms;
  transform: scale(1.1);
}
@media (max-width: 1100px) {
  .container {
    margin-top: 1.8rem;
  }
}

